import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { Fetch_Url } from "../../../Context/Context";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function SignIn() {
  const navigate = useNavigate();
  const [number, setNumber] = useState();
  const [password, setPassword] = useState([]);
  const [hide, setHide] = useState(false);
  const codeNum = "+91" + number;

  const login = () => {
    navigator.vibrate(100);
    const formdata = new FormData();
    formdata?.append("username", codeNum);
    formdata?.append("password", password);
    number?.length === 10 && password?.length > 0
      ? axios
          .post(`${Fetch_Url}/userapi.php?action=login`, formdata, {})
          .then((resp) => {
            // console.log(resp, "login Data");
            resp?.data?.error === false
              ? localStorage.setItem("loginData", number) ||
                (toast.success("LoggedIn Successfull", {
                  position: toast.POSITION.BOTTOM_LEFT,
                }) &&
                  setTimeout(() => {
                    navigate("/");
                  }, 1000))
              : toast.error("Wrong Credentials", {
                  position: toast.POSITION.BOTTOM_LEFT,
                });
          })
          .catch((err) => {
            console.log(err);
          })
      : toast.error("Input Empty Or Mobile No. not Valid", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
  };

  return (
    <>
      <ToastContainer />
      <div className="slotHeadText">
        <i
          className="fa fa-arrow-left"
          onClick={() => {
            navigate("/");
          }}
        ></i>
        <h3>Login</h3>
      </div>
      <div style={{ backgroundColor: "#0A1846", color: "#fff" }}>
        <div class="px-3 pb-3">
          <div>
            <div>
              Please log in with your phone number.
            </div>
            <div>
              Please click on Forgot Password? option to reset your password...
            </div>
          </div>
        </div>
      </div>
      <div className="containerrr">
        <div className="formWrapper">
          <div className="signinBox">
            <div className="signinInput">
              <label className="signinLabel">
                Enter Mobile Number
              </label>
              <input
                type="number"
                placeholder="Mobile Number"
                onChange={(e) => setNumber(e.target.value)}
                className="custom-input"
                style={{ backgroundColor: "#2B3270", outline: "none", borderRadius: "10px", color: "#fff" }}
              />
            </div>
            <div className="signinInput">
              <label className="signinLabel">
                Enter Password
              </label>
              <input
                type={hide ? "text" : "password"}
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
                className="custom-input"
                style={{ backgroundColor: "#2B3270", outline: "none", borderRadius: "10px", color: "#fff" }}
              />
              <i
                className="fa fa-eye"
                style={{ color: "gray" }}
                onClick={() => setHide(!hide)}
              ></i>
            </div>
            <div className="signinBtn" onClick={login}>
              <button style={{ borderRadius: "40px", backgroundColor: "#1989FA" }}>Login</button>
            </div>
            <div className="signinInfo">
              <Link to="/signup">
                <h4
                  onClick={() => navigator.vibrate(100)}
                  className="register-btn"
                >
                  Register
                </h4>
              </Link>
              <Link to="/forgotpassword">
                <h4
                  onClick={() => navigator.vibrate(100)}
                  className="forgot-password-btn"
                >
                  Forgot Password?
                </h4>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

/* SignIn CSS */
