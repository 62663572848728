import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "@mui/material/Modal";
import Box from '@mui/material/Box';
import { mine_Url } from "../../Context/Context";
import ReactAudioPlayer from "react-audio-player";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 320,
    bgcolor: "white",
    boxShadow: 24,
    border: "none",
    borderRadius: "20px"
};

const moneydata = [
    {
        id: 1,
        money: 10,
    },
    {
        id: 2,
        money: 100,
    },
    {
        id: 3,
        money: 1000,
    },
    {
        id: 4,
        money: 10000,
    },
];

const numbers = [
    {
        id: 1,
        percent: 78.4
    },
    {
        id: 2,
        percent: 23.5
    },
    {
        id: 3,
        percent: 31.3
    },
    {
        id: 4,
        percent: 1
    },
    {
        id: 5,
        percent: 0
    },
    {
        id: 6,
        percent: 94
    },
    {
        id: 7,
        percent: 0
    },
    {
        id: 8,
        percent: 62.7
    },
    {
        id: 9,
        percent: 0
    }
]

export default function Mine() {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [betstatus, setBetstatus] = useState(false);
    const [bomb, setBomb] = useState(false);
    const [bomb2, setBomb2] = useState(false);
    const [winmodal, setWinmodal] = useState(false);
    const [winmodal2, setWinmodal2] = useState(false);
    const [value, setValue] = useState(0);
    const [userdetailWin, setUserdetailWin] = useState([]);
    const [betdata, setBetdata] = useState([]);
    const [page, setPage] = useState(1);
    const [intvalue, setIntvalue] = useState(1);
    const [greenbtn, setGreenbtn] = useState({
        id: 1,
        money: 10,
    });

    let contractMoney = greenbtn?.money * intvalue;
    // console.log(contractMoney, "contractMoney");

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const userInfo = () => {
        axios
            .post(`${mine_Url}/bet.php?action=info&user=${window.$loginData}`, {})
            .then((resp) => {
                console.log(resp, "resp userInfo data");
                setUserdetailWin(resp?.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const [bal] = userdetailWin;
    // console.log(bal, "bal");

    const betRecord = () => {
        axios
            .post(`${mine_Url}/Index.php`, {
                aksi: "proses_my_bet_record",
                Username: window.$loginData,
                Page: page
            })
            .then((resp) => {
                console.log(resp, "resp betRecord data");
                setBetdata(resp?.data?.result);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const betClick = () => {
        navigator.vibrate(100);
        if (contractMoney <= bal?.balance) {
            axios
                .post(`${mine_Url}/Index.php`, {
                    aksi: "proses_betting",
                    Username: window.$loginData,
                    Server: value === 0 ? "3x3" : "4x4",
                    BetAmount: contractMoney
                })
                .then((resp) => {
                    console.log(resp, "betClick resp data");
                    if (resp?.data?.result === "Bet Added Successfully") {
                        toast.success(resp?.data?.result, {
                            position: toast.POSITION.BOTTOM_LEFT,
                        });
                        setOpen(false);
                        setBetstatus(true);
                        userInfo()
                    }
                    else {
                        setOpen(false);
                        toast.error(resp?.data?.result, {
                            position: toast.POSITION.BOTTOM_LEFT,
                        });
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            toast.error("Insufficient Balance", {
                position: toast.POSITION.BOTTOM_LEFT,
            });
        }
    };

    useEffect(() => {
        userInfo();
    }, []);

    // console.log(contractMoney * numbers?.percent / 100, "jkjkjkj")

    // const arrayOfObjects = [
    //     { id: 1, value: 10 },
    //     { id: 2, value: 20 },
    //     { id: 3, value: 30 },
    //     { id: 4, value: 40 },
    //     { id: 5, value: 50 }
    // ];

    // const printRandomInteger = () => {
    //     const randomIndex = Math.floor(Math.random() * numbers.length);
    //     const randomObject = numbers[randomIndex];
    //     setRandomInteger(randomObject?.percent);
    // };

    // console.log(randomInteger, "78jhjhjh")

    // const numbers = [
    //     { id: 1, value: 10 },
    //     { id: 2, value: 20 },
    //     { id: 3, value: 30 },
    //     { id: 4, value: 40 },
    //     { id: 5, value: 50 }
    // ];

    // Initialize state for each div
    const [values, setValues] = useState(Array(9).fill(null));
    const [values2, setValues2] = useState(Array(16).fill(null));

    const printRandomInteger = (index) => {
        const randomIndex = Math?.floor(Math?.random() * numbers?.length);
        const randomValue = numbers[randomIndex]?.percent;

        // Update the state for the specific div
        const newValues = [...values];
        newValues[index] = randomValue;
        setValues(newValues);

        if (randomValue === 0) {
            zeroCashout(v?.length)
            setTimeout(() => {
                setBomb(true)
            }, 800);
        }
    };

    let v = []
    values?.filter(item => {
        if (item !== null) {
            return v?.push(contractMoney * item / 100)
        }
    })
    console.log(v, "hhhhh")

    let totalAmount = 0;
    v?.map((item) => (
        totalAmount += item
    ))

    console.log(totalAmount, "yyyyyyyyyy")

    const printRandomInteger2 = (index) => {
        const randomIndex = Math?.floor(Math?.random() * numbers?.length);
        const randomValue = numbers[randomIndex]?.percent;

        // Update the state for the specific div
        const newValues = [...values2];
        newValues[index] = randomValue;
        setValues2(newValues);

        if (randomValue === 0) {
            zeroCashout(v2?.length)
            setTimeout(() => {
                setBomb2(true)
            }, 800);
        }
    };

    let v2 = []
    values2?.filter(item => {
        if (item !== null) {
            return v2?.push(contractMoney * item / 100)
        }
    })

    let totalAmount2 = 0;
    v2?.map((item) => (
        totalAmount2 += item
    ))

    const [timeLeft, setTimeLeft] = useState(0);

    const zeroCashout = (clickItems) => {
        axios
            .post(`${mine_Url}/Index.php`, {
                aksi: "Cashout",
                Username: window.$loginData,
                Pass: clickItems,
                Status: "Failed",
                WinningPrice: 0
            })
            .then((resp) => {
                console.log(resp, "resp zeroCashout data");
            })
            .catch((err) => {
                console.log(err);
            })
    };

    const threeCashout = () => {
        totalAmount > 0 && (
            axios
                .post(`${mine_Url}/Index.php`, {
                    aksi: "Cashout",
                    Username: window.$loginData,
                    Pass: v?.length,
                    Status: "Success",
                    WinningPrice: totalAmount
                })
                .then((resp) => {
                    console.log(resp, "resp threeCashout data");
                    resp?.data?.success === true && setWinmodal(true)
                })
                .catch((err) => {
                    console.log(err);
                })
        )
    };

    const fourCashout = () => {
        totalAmount2 > 0 && (
            axios
                .post(`${mine_Url}/Index.php`, {
                    aksi: "Cashout",
                    Username: window.$loginData,
                    Pass: v2?.length,
                    Status: "Success",
                    WinningPrice: totalAmount2
                })
                .then((resp) => {
                    console.log(resp, "resp fourCashout data");
                    resp?.data?.success === true && setWinmodal2(true)
                })
                .catch((err) => {
                    console.log(err);
                })
        )
    };

    useEffect(() => {
        if (timeLeft === 0) return;

        const timer = setInterval(() => {
            setTimeLeft(prevTime => prevTime - 1);
        }, 1000);

        return () => clearInterval(timer);
    }, [timeLeft]);

    const startCountdown = () => {
        setTimeLeft(120); // Start countdown from 120 seconds
    };

    useEffect(() => {
        betstatus && startCountdown()
    }, [betstatus])

    useEffect(() => {
        betRecord()
    }, [page])

    useEffect(() => {
        if (timeLeft === 1) {
            window?.location?.reload(true)
        }
    }, [timeLeft])

    return (
        <>
            {/* <div>
                <div className={isDisabled ? 'disabled-div' : ''} onClick={(e) => isDisabled && e.stopPropagation()}>
                    Click Me!
                </div>
                <button onClick={() => setIsDisabled(!isDisabled)}>
                    {isDisabled ? 'Enable' : 'Disable'} Div
                </button>
            </div> */}
            {/* <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                {values.map((value, index) => (
                    <div
                        key={index}
                        onClick={() => {
                            if (value === null) {
                                printRandomInteger(index)
                            }
                        }
                        }
                        style={{
                            width: '100px',
                            height: '100px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            border: '1px solid black',
                            cursor: 'pointer'
                        }}
                    >
                        {value !== null ? contractMoney * value / 100 : 'Click me'}
                    </div>
                ))}
            </div> */}
            {/* <div>
                <button onClick={printRandomInteger}>Print Random Integer</button>
                {randomInteger !== null && <p>Random Integer: {randomInteger}</p>}
            </div> */}
            <ToastContainer />
            <div className="luckyHead">
                <i
                    className="fa fa-arrow-left"
                    onClick={() => {
                        navigate("/");
                    }}
                ></i>
                <h3>Mine Sweeper</h3>
                <h4>
                    &#8377; {bal?.balance}
                </h4>
                <i
                    className="fa fa-credit-card"
                    onClick={() => {
                        navigate("/recharge", { state: bal }
                        );
                    }}
                ></i>
            </div>
            <div className="mineInfo">
                <div className="mineFlex">
                    <div className="mineTime">
                        <i className="fa fa-clock"></i>
                        <h2>{timeLeft}s</h2>
                    </div>
                    <div className="mineMoney">
                        <h2><span>&#8377;</span>{value === 0 ? totalAmount?.toFixed(2) : totalAmount2?.toFixed(2)}</h2>
                    </div>
                </div>
                <Box
                    sx={{
                        width: "100%",
                        marginTop: "15px"
                    }}
                >
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                        className="diceTabs"
                    >
                        <Tab label="3x3" {...a11yProps(0)} />
                        <Tab label="4x4" {...a11yProps(1)} />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <div className="mineAllBox">
                        {values?.map((value, index) => (
                            <div className="mineBox"
                                key={index}
                                onClick={() => {
                                    if (value === null && betstatus === true) {
                                        printRandomInteger(index)
                                    }
                                }}
                            >
                                {value !== null && value !== 0 &&
                                    <div>
                                        <h4>{contractMoney * value / 100}</h4>
                                        <img src="ms_box_op.69df5435.png" alt="" />
                                    </div>
                                }
                                {value === 0 &&
                                    <div>
                                        <div className="bombImg">
                                            <img src="ms_bomb.bc726b98.png" alt="" />
                                        </div>
                                        <div className="audio">
                                            <ReactAudioPlayer
                                                src="my-first-recorded-explosion-103816.mp3"
                                                autoPlay
                                                controls
                                            />
                                        </div>
                                    </div>
                                }
                                {bomb === true
                                    &&
                                    <Modal
                                        open={true}
                                        aria-labelledby="modal-modal-title"
                                        aria-describedby="modal-modal-description"
                                    >
                                        <Box sx={style}>
                                            <div className='diceResult'>
                                                <h2>Loss</h2>
                                            </div>
                                            <div className='diceAllout'>
                                                <div className='diceOutput'>
                                                    <h4>Bet Amount</h4>
                                                    <h4>&#8377; {contractMoney}</h4>
                                                </div>
                                                <div className='diceOutput'>
                                                    <h4>Total Amount</h4>
                                                    <h4>&#8377; 0</h4>
                                                </div>
                                            </div>
                                            <div className='diceClose' onClick={() => { window?.location?.reload(true) }} >
                                                <button>Close</button>
                                            </div>
                                        </Box>
                                    </Modal>
                                }
                            </div>
                        ))}
                        {/* <div className="mineBox" onClick={printRandomInteger}>
                        <h4>{randomInteger}</h4>
                        <img src="ms_box_op.69df5435.png" alt="" />
                    </div>
                    <div className="mineBox" onClick={printRandomInteger}>
                    </div>
                    <div className="mineBox" onClick={printRandomInteger}>
                    </div>
                    <div className="mineBox" onClick={printRandomInteger}>
                    </div>
                    <div className="mineBox">
                    </div>
                    <div className="mineBox">
                    </div>
                    <div className="mineBox">
                    </div>
                    <div className="mineBox">
                    </div> */}
                        {betstatus === false &&
                            <div className="mineStart"
                                onClick={() => {
                                    setOpen(true);
                                }}
                            >
                                <h2>Start</h2>
                            </div>
                        }
                        <Modal
                            open={open}
                            onClose={() => {
                                setOpen(false);
                            }}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={style}>
                                <div className="diceBlueHead">
                                    <h2>Mine</h2>
                                </div>
                                <div className="greenHeadBox">
                                    <div className="greenHeadTxt">
                                        <h5>Contract Money</h5>
                                    </div>
                                    <div className="greenHeadBtn">
                                        {moneydata?.map((item) => {
                                            return (
                                                <>
                                                    <div
                                                        className={
                                                            item?.id === greenbtn?.id ? "clickedColor" : ""
                                                        }
                                                    >
                                                        <button
                                                            onClick={() => {
                                                                setGreenbtn(item);
                                                            }}
                                                        >
                                                            {item?.money}
                                                        </button>
                                                    </div>
                                                </>
                                            );
                                        })}
                                    </div>
                                    <div className="greenHeadTxt">
                                        <h5>Number</h5>
                                    </div>
                                    <div className="greenHeadCount">
                                        <button
                                            disabled={intvalue <= 1}
                                            onClick={() => {
                                                setIntvalue(intvalue - 1);
                                            }}
                                        >
                                            <i className="fa fa-minus"></i>
                                        </button>
                                        <h3>{intvalue}</h3>
                                        <button
                                            onClick={() => {
                                                setIntvalue(intvalue + 1);
                                            }}
                                        >
                                            <i className="fa fa-plus"></i>
                                        </button>
                                    </div>
                                    <div className="greenHeadTxt">
                                        <h5>Total Contract Money is {contractMoney}</h5>
                                    </div>
                                    <div className="greenHeadTxt2">
                                        <h4>
                                            <i className="fa fa-check"></i>I agree
                                            <span>PRESALE RULE</span>
                                        </h4>
                                    </div>
                                    <div className="signinModalBtn">
                                        <button
                                            onClick={() => {
                                                setOpen(false);
                                            }}
                                        >
                                            CANCEL
                                        </button>
                                        <button onClick={betClick}>CONFIRM</button>
                                    </div>
                                </div>
                            </Box>
                        </Modal>
                    </div>
                    <div className="mineCash">
                        <button onClick={threeCashout}>Cashout</button>
                    </div>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <div className="mineFourBox">
                        {values2?.map((value, index) => (
                            <div className="mineFour"
                                key={index}
                                onClick={() => {
                                    if (value === null && betstatus === true) {
                                        printRandomInteger2(index)
                                    }
                                }}
                            >
                                {value !== null && value !== 0 &&
                                    <div>
                                        <h4>{contractMoney * value / 100}</h4>
                                        <img src="ms_box_op.69df5435.png" alt="" />
                                    </div>
                                }
                                {value === 0 &&
                                    <div>
                                        <div className="bombFour">
                                            <img src="ms_bomb.bc726b98.png" alt="" />
                                        </div>
                                        <div className="audio">
                                            <ReactAudioPlayer
                                                src="my-first-recorded-explosion-103816.mp3"
                                                autoPlay
                                                controls
                                            />
                                        </div>
                                    </div>
                                }
                                {
                                    bomb2 === true &&
                                    < Modal
                                        open={true}
                                        aria-labelledby="modal-modal-title"
                                        aria-describedby="modal-modal-description"
                                    >
                                        <Box sx={style}>
                                            <div className='diceResult'>
                                                <h2>Loss</h2>
                                            </div>
                                            <div className='diceAllout'>
                                                <div className='diceOutput'>
                                                    <h4>Bet Amount</h4>
                                                    <h4>&#8377; {contractMoney}</h4>
                                                </div>
                                                <div className='diceOutput'>
                                                    <h4>Total Amount</h4>
                                                    <h4>&#8377; 0</h4>
                                                </div>
                                            </div>
                                            <div className='diceClose' onClick={() => { window?.location?.reload(true) }} >
                                                <button>Close</button>
                                            </div>
                                        </Box>
                                    </Modal>
                                }
                            </div>
                        ))}
                        {/* <div className="mineBox" onClick={printRandomInteger}>
                        <h4>{randomInteger}</h4>
                        <img src="ms_box_op.69df5435.png" alt="" />
                    </div>
                    <div className="mineBox" onClick={printRandomInteger}>
                    </div>
                    <div className="mineBox" onClick={printRandomInteger}>
                    </div>
                    <div className="mineBox" onClick={printRandomInteger}>
                    </div>
                    <div className="mineBox">
                    </div>
                    <div className="mineBox">
                    </div>
                    <div className="mineBox">
                    </div>
                    <div className="mineBox">
                    </div> */}
                        {betstatus === false &&
                            <div className="mineStart"
                                onClick={() => {
                                    setOpen(true);
                                }}
                            >
                                <h2>Start</h2>
                            </div>
                        }
                        <Modal
                            open={open}
                            onClose={() => {
                                setOpen(false);
                            }}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={style}>
                                <div className="diceBlueHead">
                                    <h2>Mine</h2>
                                </div>
                                <div className="greenHeadBox">
                                    <div className="greenHeadTxt">
                                        <h5>Contract Money</h5>
                                    </div>
                                    <div className="greenHeadBtn">
                                        {moneydata?.map((item) => {
                                            return (
                                                <>
                                                    <div
                                                        className={
                                                            item?.id === greenbtn?.id ? "clickedColor" : ""
                                                        }
                                                    >
                                                        <button
                                                            onClick={() => {
                                                                setGreenbtn(item);
                                                            }}
                                                        >
                                                            {item?.money}
                                                        </button>
                                                    </div>
                                                </>
                                            );
                                        })}
                                    </div>
                                    <div className="greenHeadTxt">
                                        <h5>Number</h5>
                                    </div>
                                    <div className="greenHeadCount">
                                        <button
                                            disabled={intvalue <= 1}
                                            onClick={() => {
                                                setIntvalue(intvalue - 1);
                                            }}
                                        >
                                            <i className="fa fa-minus"></i>
                                        </button>
                                        <h3>{intvalue}</h3>
                                        <button
                                            onClick={() => {
                                                setIntvalue(intvalue + 1);
                                            }}
                                        >
                                            <i className="fa fa-plus"></i>
                                        </button>
                                    </div>
                                    <div className="greenHeadTxt">
                                        <h5>Total Contract Money is {contractMoney}</h5>
                                    </div>
                                    <div className="greenHeadTxt2">
                                        <h4>
                                            <i className="fa fa-check"></i>I agree
                                            <span>PRESALE RULE</span>
                                        </h4>
                                    </div>
                                    <div className="signinModalBtn">
                                        <button
                                            onClick={() => {
                                                setOpen(false);
                                            }}
                                        >
                                            CANCEL
                                        </button>
                                        <button onClick={betClick}>CONFIRM</button>
                                    </div>
                                </div>
                            </Box>
                        </Modal>
                    </div>
                    <div className="mineCash">
                        <button onClick={fourCashout}>Cashout</button>
                    </div>
                </TabPanel >
                {winmodal &&
                    <Modal
                        open={true}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style}>
                            <div className='diceResult'>
                                <h2>Win</h2>
                            </div>
                            <div className='diceAllout'>
                                <div className='diceOutput'>
                                    <h4>Bet Amount</h4>
                                    <h4>&#8377; {contractMoney}</h4>
                                </div>
                                <div className='diceOutput'>
                                    <h4>Total Amount</h4>
                                    <h4>&#8377; {totalAmount}</h4>
                                </div>
                            </div>
                            <div className='diceClose' onClick={() => { window?.location?.reload(true) }} >
                                <button>Close</button>
                            </div>
                        </Box>
                    </Modal>
                }
                {winmodal2 &&
                    <Modal
                        open={true}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style}>
                            <div className='diceResult'>
                                <h2>Win</h2>
                            </div>
                            <div className='diceAllout'>
                                <div className='diceOutput'>
                                    <h4>Bet Amount</h4>
                                    <h4>&#8377; {contractMoney}</h4>
                                </div>
                                <div className='diceOutput'>
                                    <h4>Total Amount</h4>
                                    <h4>&#8377; {totalAmount2}</h4>
                                </div>
                            </div>
                            <div className='diceClose' onClick={() => { window?.location?.reload(true) }} >
                                <button>Close</button>
                            </div>
                        </Box>
                    </Modal>
                }
            </div>
            <div className='diceRecordHead'
                style={{
                    marginTop: "15px",
                    boxShadow: "0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12)"
                }}
            >
                <h3>My Order</h3>
            </div >
            <div className='diceTable'>
                <table>
                    <thead>
                        <tr>
                            <th>Server</th>
                            <th>Point</th>
                            <th>Pass</th>
                            <th>Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        {betdata?.map((item) => {
                            return (
                                <>
                                    <tr>
                                        <td>
                                            {item?.pass}
                                        </td>
                                        <td>{item?.BetAmount}</td>
                                        <td>{item?.total_unboxing}</td>
                                        <td>{item?.price}</td>
                                    </tr>
                                </>
                            )
                        })}
                    </tbody>
                </table>
            </div>
            <div className="pageBtn">
                <button
                    onClick={() => setPage(page - 1)}
                    disabled={page === 1}
                >
                    <i className="fa fa-angle-left"></i>
                </button>
                <button onClick={() => setPage(page + 1)}>
                    <i className="fa fa-angle-right"></i>
                </button>
            </div>
        </>
    )
}
