import { useNavigate, Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { Fetch_Url } from "../../../Context/Context";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// mui
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function Withdrawal() {
  const navigate = useNavigate();
  const location = useLocation();
  const userState = location?.state;
  // console.log(userState, "userState");

  const [expanded, setExpanded] = useState(false);
  const [amount, setAmount] = useState([]);
  const [selectupi, setSelectupi] = useState([]);
  const [password, setPassword] = useState([]);
  const [bankinfo, setBankinfo] = useState([]);
  const [hide, setHide] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const bankCard = () => {
    axios
      .post(
        `${Fetch_Url}/bet.php?action=bankcard&user=${window?.$loginData}`,
        {}
      )
      .then((resp) => {
        // console.log(resp, "bank Data");
        setBankinfo(resp?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const checkPassword = () => {
    navigator.vibrate(100);
    if (amount > 0 && selectupi?.length > 0 && password?.length > 0) {
      const formdata = new FormData();
      formdata?.append("username", "+91" + window?.$loginData);
      formdata?.append("password", password);
      axios
        .post(`${Fetch_Url}/userapi.php?action=login`, formdata, {})
        .then((resp) => {
          // console.log(resp, "checkPassword Data");
          resp?.data?.error === false
            ? withdraw()
            : toast.error("Wrong Password", {
                position: toast.POSITION.BOTTOM_LEFT,
              });
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (amount <= 0) {
      toast.error("Amount Should be Greater than 0", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else {
      toast.error("Input Empty or UPI not Selected", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
  };

  const withdraw = () => {
    axios
      .post(`${Fetch_Url}/withdrawwal.php`, {
        aksi: "process_withdrawal",
        username: window?.$loginData,
        amount: amount,
        upi: selectupi,
      })
      .then((resp) => {
        // console.log(resp, "resp data");
        if (resp?.data?.msg === "Withdrawal Successfull") {
          toast.success(resp?.data?.msg, {
            position: toast.POSITION.BOTTOM_LEFT,
          });
          setTimeout(() => {
            navigate("/withrecord");
          }, 2000);
        } else {
          toast.error(resp?.data?.msg, {
            position: toast.POSITION.BOTTOM_LEFT,
          });
        }
        // if (resp?.data?.msg === "Withdrawal Successfull") {
        //   toast.success("Withdrawal Successfull", {
        //     position: toast.POSITION.BOTTOM_LEFT,
        //   });
        //   setTimeout(() => {
        //     navigate("/withrecord");
        //   }, 2000);
        // } else if (resp?.data?.msg === "Withdrawal Failed Try Again") {
        //   toast.error("Withdrawal Failed Try Again", {
        //     position: toast.POSITION.BOTTOM_LEFT,
        //   });
        // } else if (resp?.data?.msg === "Your Invites Not Recharged") {
        //   toast.error("Your Invites Not Recharged", {
        //     position: toast.POSITION.BOTTOM_LEFT,
        //   });
        // } else if (resp?.data?.msg === "Minimum Withdrawal Amount 211") {
        //   toast.error("Minimum Withdrawal Amount 211", {
        //     position: toast.POSITION.BOTTOM_LEFT,
        //   });
        // } else if (resp?.data?.msg === "Somthing Want Wrong") {
        //   toast.error("Something Went Wrong", {
        //     position: toast.POSITION.BOTTOM_LEFT,
        //   });
        // } else if (resp?.data?.msg === "Add Minimum 2 Players For Withdrawal") {
        //   toast.error("Add Minimum 2 Players For Withdrawal", {
        //     position: toast.POSITION.BOTTOM_LEFT,
        //   });
        // } else if (
        //   resp?.data?.msg === "You Need To Recharge First For Withdrawal"
        // ) {
        //   toast.error("You Need To Recharge First For Withdrawal", {
        //     position: toast.POSITION.BOTTOM_LEFT,
        //   });
        // } else if (
        //   resp?.data?.msg ===
        //   "Please Select Withdrawal Amount Under Your Available Amount"
        // ) {
        //   toast.error(
        //     "Please Select Withdrawal Amount Under Your Available Amount",
        //     {
        //       position: toast.POSITION.BOTTOM_LEFT,
        //     }
        //   );
        // }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const feeDeduct = (amount * 5) / 100;
  // console.log(feeDeduct, "feeDeduct");

  const remainAmount = amount - feeDeduct;
  // console.log(remainAmount, "remainAmount");

  useEffect(() => {
    bankCard();
  }, []);

  return (
    <>
      <ToastContainer />
      <div className="miniHead">
        <i
          className="fa fa-arrow-left"
          onClick={() => {
            navigate(-1);
          }}
        ></i>
        <h3>Withdrawal</h3>
        <i
          className="fa fa-align-left"
          onClick={() => {
            navigate("/withrecord");
          }}
        ></i>
      </div>
      <div className="withCont">
        <div className="balText">
          <h2>Balance: &#x20b9; {userState?.balance}</h2>
          <input
            type="number"
            placeholder="Enter Withdrawal Amount"
            onChange={(e) => {
              setAmount(e.target.value);
            }}
          />
        </div>
        <div className="withCard">
          <div className="withText">
            <h5>
              Fee: {feeDeduct} , To Account {remainAmount}
            </h5>
          </div>
          <div className="withText2">
            <h5>Payout</h5>
          </div>
          <div className="withData">
            <i className="fa fa-check"></i>
            <h5>BankCard</h5>
          </div>
          <div>
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
              className="withInfo"
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography
                  sx={{
                    width: "20%",
                    paddingLeft: "1px",
                    color: "gray",
                    flexShrink: 0,
                    fontSize: "20px",
                  }}
                >
                  <i className="fa fa-wallet"></i>
                </Typography>
                <Typography
                  sx={{
                    paddingTop: "3px",
                    fontWeight: "600",
                    color: "gray",
                  }}
                >
                  {selectupi?.length > 0 ? selectupi : "Select Bank Card"}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {bankinfo?.map((item) => {
                  return (
                    <>
                      <Typography>
                        <div
                          className={
                            selectupi === item?.upi
                              ? "clickedUpi"
                              : "withListBox"
                          }
                          onClick={() => {
                            setSelectupi(item?.upi);
                            setExpanded(false);
                          }}
                        >
                          <h4>{item?.upi}</h4>
                        </div>
                      </Typography>
                    </>
                  );
                })}
                <Typography
                  sx={{
                    marginTop: "10px",
                    border: "1px solid gray",
                    borderRadius: "3px",
                  }}
                >
                  <Link to="/addbankcard">
                    <div className="withListBox">
                      <h4>Add Bank Card</h4>
                    </div>
                  </Link>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
          <div className="withInput">
            <input
              type={hide ? "text" : "password"}
              placeholder="Enter Your Login Password"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
            <i
              className="fa fa-eye"
              onClick={() => {
                setHide(!hide);
              }}
            ></i>
          </div>
          <div className="signinBtnCard" onClick={checkPassword}>
            <button>Withdrawal</button>
          </div>
        </div>
      </div>
    </>
  );
}

// Withdrawal CSS
