import { Route, Routes } from "react-router-dom"
import "./App.css"
import Profile from "./Components/Profile/Profile"
import Win from "./Components/Win/Win"
import Search from "./Components/Search/Search"
import ForgotPassword from "./Components/SignIn&SignUp/ForgotPassword/ForgotPassword"
import SignIn from "./Components/SignIn&SignUp/SignIn/SignIn"
import SignUp from "./Components/SignIn&SignUp/SignUp/SignUp"
import Home from "./Home/Home"
import BankCard from "./Components/Profile/BankCard/BankCard"
import AddBankCard from "./Components/Profile/BankCard/AddBankCard/AddBankCard"
import Address from "./Components/Profile/Address/Address"
import AddADDRESS from "./Components/Profile/Address/AddADDRESS/AddADDRESS"
import Recharge from "./Components/Recharge/Recharge"
import Orders from "./Components/Profile/Orders/Orders"
import Reward from "./Components/Profile/Reward/Reward"
import Intrest from "./Components/Profile/Intrest/Intrest"
import RechargeRecord from "./Components/Recharge/RechargeRecord/RechargeRecord"
import Transactions from "./Components/Recharge/Transactions/Transactions"
import Complaints from "./Components/Profile/Complaints/Complaints"
import AddComplaints from "./Components/Profile/Complaints/AddComplaints/AddComplaints"
import Withdrawal from "./Components/Recharge/Withdrawal/Withdrawal"
import WithRecord from "./Components/Recharge/Withdrawal/WithRecord/WithRecord"
import Promotion from "./Components/Profile/Promotion/Promotion"
import Aviator from "./Components/Aviator/Aviator"
import LuckyHit from "./Components/LuckyHit/LuckyHit"
import Ludo from "./Components/Ludo/Ludo"
import Dice from "./Components/Dice/Dice"
import AndarBahar from "./Components/AndarBahar/AndarBahar"
import Mine from "./Components/Mine/Mine"
// import TeenPatti from "./Components/TeenPatti/TeenPatti"

function App() {
  window.$loginData = localStorage.getItem("loginData")
  // console.log(window.$loginData, "logindata");

  return (
    <>
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/signin" element={<SignIn />}></Route>
        <Route path="/signup" element={<SignUp />}></Route>
        <Route path="/forgotpassword" element={<ForgotPassword />}></Route>
        <Route path="/win" element={<Win />}></Route>
        <Route path="/search" element={<Search />}></Route>
        <Route path="/profile" element={<Profile />}></Route>
        <Route path="/promotion" element={<Promotion />}></Route>
        <Route path="/bankcard" element={<BankCard />}></Route>
        <Route path="/addbankcard" element={<AddBankCard />}></Route>
        <Route path="/address" element={<Address />}></Route>
        <Route path="/addaddress" element={<AddADDRESS />}></Route>
        <Route path="/orders" element={<Orders />}></Route>
        <Route path="/reward" element={<Reward />}></Route>
        <Route path="/intrest" element={<Intrest />}></Route>
        <Route path="/complaints" element={<Complaints />}></Route>
        <Route path="/addcomplaints" element={<AddComplaints />}></Route>
        <Route path="/recharge" element={<Recharge />}></Route>
        <Route path="/rechargerecord" element={<RechargeRecord />}></Route>
        <Route path="/withdrawal" element={<Withdrawal />}></Route>
        <Route path="/withrecord" element={<WithRecord />}></Route>
        <Route path="/transactions" element={<Transactions />}></Route>
        <Route path="/aviator" element={<Aviator />}></Route>
        <Route path="/luckyhit" element={<LuckyHit />}></Route>
        <Route path="/ludo" element={<Ludo />}></Route>
        <Route path="/dice" element={<Dice />}></Route>
        <Route path="/andarbahar" element={<AndarBahar />}></Route>
        <Route path="/mine" element={<Mine />}></Route>
        {/* <Route path="/teenpatti" element={<TeenPatti />}></Route> */}
      </Routes>
    </>
  )
}

export default App

// css good
// uninstall extra library

// bet 1 time Completed
// random number & money in color game Completed
// change gif Completed
// local storage data username Completed
// recharge API Completed
// color black & red Completed
// sound play Completed
// wingo colorwins link Completed

// aviator changes Completed:-
// + - disable & input disable in cashout Completed
// show multiply (time * cashout amount) Completed
// check pause time * cashout amount Completed
// telegram icon & link Completed
// change numbers color in win page Completed
// change purple color in period id & price Completed
// show winning amount in toast Completed
// check random table show in server Completed

// Add random table in colorwin game Completed
// periodID color & all element color changes Completed
// logo implement Completed
// promotion & recharge link changes Completed

// lucky hit changes Completed:-
// slot machine scroll Completed
// border radius, box shadow Completed
// play sound 30 second Completed

// aviator:-
// fly right side animation
// border remove Completed
// loader sound remove Completed

// luck hit:- 
// result 2 API Completed
// homepage changes Completed

// aviator color changes Completed
// mine modal changes Completed
// mine 4*4 Completed
// mine cashout work Completed
// andar bahar css animation Completed
// dice scrollbar big Completed

// code formatted in Mine
// start button work double in mine
// lucky hit color changed result status & show result img

// new changes Completed:-
// all UI app color changes Completed
// all game table color changes Completed
// promotion color changes Completed
// profile color changes Completed
